//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { filter, get, isEmpty, join, map, split, upperFirst } from 'lodash'
import md5 from 'md5'
// TODO: Investigate why these can't be loaded implicitly
import SectionGallery3ColumnsPerRow from '@/components/section/gallery/3ColumnsPerRow.vue'
import SectionGallery4Columns from '@/components/section/gallery/4Columns.vue'
import CampaignPageImagePanelsSection from '@/components/news-events/CampaignPageImagePanelsSection.vue'
import CampaignPageSectionHeader from '@/components/news-events/CampaignPageSectionHeader.vue'

const defaultOverrideComponents = {
    // TODO: Migrate these layouts in Contentful
    gallery_1row_bottom: 'SectionGallerySingleRow',
    media_bottom: 'SectionMediaAtBottom',
    subsection: 'SectionStack',
    subsection_bottom: 'SectionStack',
    subsection_bottom_media_right: 'SectionStackWithMediaRight',
}

export default {
    props: {
        sections: {
            type: [Array, Object],
            required: true
        },
        gapClasses: {
            type: String,
            default: 'space-y-36'
        },
        overrideComponents: {
            type: Object,
            default: () => ({})
        }
    },
    components: {
        // TODO: Investigate why these can't be loaded implicitly
        SectionGallery3ColumnsPerRow,
        SectionGallery4Columns,
        campaignPageImagePanelsSection: CampaignPageImagePanelsSection,
        campaignPageSectionHeader: CampaignPageSectionHeader,
    },
    methods: {
        filter, get,
        generateDomId: function (section) {
            return `s-${md5(get(section, 'sys.id'))}`
        },
        getComponentName: function (layoutName) {
            // Allow override
            const overrideComponents = { ...defaultOverrideComponents, ...this.overrideComponents }
            if (layoutName in overrideComponents) {
                return overrideComponents[layoutName]
            }

            layoutName = join(map(split(layoutName, '-'), upperFirst), '')
            layoutName = join(map(split(layoutName, '_'), upperFirst), '')
            return 'Section' + upperFirst(layoutName)
        },
        isComponentExist: function (name) {
            return name in this.$options.components
        },
        isPublished: function (section) {
            // Skip draft
            return !isEmpty(get(section, 'fields'))
        },
        isVisible: function (section) {
            return this.isPublished(section) && (get(section, 'fields.layout') !== 'None')
        }
    }
}
