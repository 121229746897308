import { render, staticRenderFns } from "./4Columns.vue?vue&type=template&id=2bdd835a&"
import script from "./4Columns.vue?vue&type=script&lang=js&"
export * from "./4Columns.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Kicker: require('/var/www/html/spa-website/components/Kicker.vue').default,SectionHeading: require('/var/www/html/spa-website/components/SectionHeading.vue').default,Markdown: require('/var/www/html/spa-website/components/Markdown.vue').default,ContentfulImage: require('/var/www/html/spa-website/components/contentful/Image.vue').default})
