//
//
//
//
//
//

export default {
    props: {
        align: {
            type: String,
            default: 'center',
            validator: function (value) {
                return ['auto', 'left', 'center', 'right'].includes(value)
            }
        },
        uppercase: {
            type: Boolean,
            default: true
        },
        weight: {
            type: String,
            default: 'light',
            validator: function (value) {
                return ['auto', 'thin', 'extralight', 'light', 'normal', 'medium', 'semibold', 'bold', 'extrabold', 'black'].includes(value)
            }
        }
    },
    computed: {
        classes: function () {
            const classes = []

            if (this.align !== 'auto') {
                classes.push(`text-${this.align}`)
            }

            if (this.uppercase) {
                classes.push('uppercase')
            }

            if (this.weight !== 'auto') {
                classes.push(`font-${this.weight}`)
            }

            return classes
        }
    }
}
