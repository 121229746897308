//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get, take } from 'lodash'

export default {
    props: {
        section: {
            type: Object,
            required: true
        }
    },
    computed: {
        items: function () {
            return take(get(this.section, 'fields.items', []), 4)
        }
    },
    methods: { get }
}
