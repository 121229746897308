//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get } from 'lodash'

export default {
    props: ['object'],
    methods: { get }
}
